import { useRef } from "react";
import { useComponent, useGeneral, useUser } from "hooks";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import RegisterFormComponent from "./components/RegisterForm.component";
import NeoMessages from "design/design_components/neo/messages/NeoMessages.base";
import { APP } from "constants";
import { useHistory } from "react-router-dom";
import ROUTES from 'routers/Routes.router.json';

const RegisterView = (_props) => {
    const user = useUser();
    const component = useComponent();
    const general = useGeneral();
    const history = useHistory();

    const messageRegisterForm = useRef(null);

    const fnRegister = component.useAsync(user.register);

    component.useEffect(() => {
        console.log(general.data)
        if (general.data.app !== APP.NEO_VALIDADOR.NAME) {
            history.push(ROUTES.index);
        }
    }, [])

    const registerFormHandleSubmit = async (_params) => {
        messageRegisterForm?.current?.clear();

        try {
            const { token } = await fnRegister({
                app: general.data.app,
                user: {
                    detail: {
                        name: _params.name,
                        lastName: _params.lastName,
                        phone: _params.phone,
                        company: _params.company,
                        email: _params.email,
                        password: _params.password
                    }
                },
                session: {
                    id: general.data.sessionId
                }
            });

            messageRegisterForm?.current?.show({
                severity: "success",
                summary: "",
                detail: `Registro exitoso`,
                life: 4000
            });

            const continueUrl = new URL(general.data.continueUrl);
            continueUrl.searchParams.set('token', token);
            window.location.replace(continueUrl);
        } catch (error) {
            if (error?.status?.code === 409) {
                messageRegisterForm?.current?.show({
                    severity: 'warn',
                    summary: '',
                    detail: 'Esta dirección de correo ya está registrada',
                    life: 4000
                });
            }
        }
    }

    return (
        <NeoCard extra="text-align-center">
            <RegisterFormComponent
                onSubmit={registerFormHandleSubmit}
                loading={component.isLoading}
            />

            <NeoMessages ref={messageRegisterForm} />
        </NeoCard>
    )
}

export default RegisterView;